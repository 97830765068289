.banner_container {
	text-align: center;
}

.banner_container > h2 {
	color: black;
	font-weight: 600;
	font-size: 2.3rem;
    margin-bottom: 1rem;
}

.banner_container > p {
	max-width: 426px;
	text-align: center;
	margin: 0 auto;
	margin-bottom: 1rem;
}

.banner_container > img {
	max-width: 500px;
	width: 100%;
	margin: 0 auto;
	object-fit: cover;
	object-position: center;
	color: black;
	font-weight: 600;
	font-size: 2.3rem;
}

@media screen and (max-width: 768px) {
    .banner_container > h2 {
        font-size: 1.5rem;
    }
}
