.inner_form_label {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.inner_form_label > p > span{
	color: var(--primary);
    font-size: .92rem;
}

.inner_form_label > small {
    margin-top: .4rem;
}

.inner_radio_check {
	display: flex;
	align-items: center !important;
	width: 100%;
	align-items: flex-start;
	border-radius: 0.6rem;
	margin-bottom: 1rem;
    margin-left: 0 !important;
    margin-right: 0 !important;
	background: var(--white-accent);
	padding: 0.3rem 0;
    text-transform: capitalize;
}

.date_picker_wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 1.2rem;
}

.country_picker {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 1.2rem;
}
