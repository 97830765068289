.container {
	padding: 2rem;
	max-width: 70dvw;
	margin: 0 auto;
}

.container form {
	background: white;
	min-height: 30dvh;
	margin-top: 9rem;
	padding: 5rem;
	border-radius: 24px;
    display: flex;
    flex-direction: column;
    gap: 2.2rem;
}


.container form button {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	background: var(--primary);
	color: white;
	padding: 1rem;
	border-radius: 0.5rem;
	border: 0;
	margin: 0 auto;
	margin-bottom: 0.5rem;
	width: 100%;
	max-width: 400px;
}

.container form button svg {
	fill: white;
}

.form_earning {
	margin-bottom: 2.5rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
}

.form_earning > p:first-child {
	color: var(--primary);
}

.form_earning h3 {
	color: black;
	font-weight: 600;
	font-size: 2.3rem;
}

.form_education {
	display: flex;
	flex-direction: column;
	gap: 1.2rem;
	align-items: flex-start;
}

.other_text_input_wrapper {
	display: block;
    width: 100%;
	margin-top: 0.5rem;
}

.other_text_input {
	padding: 1.2rem 0;
	width: 100%;
}

@media screen and (max-width: 1024px) {
	.container {
		padding: 1rem;
		max-width: 95dvw;
		margin: 0 auto;
	}

	.container form {
		padding: 1rem;
		margin-top: 2.5rem;
	}

	.form_earning h3 {
		font-size: 2rem;
	}
}
