@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
:root {
    --primary: #BE0303;
    --danger: #BF4040;
    --white-accent: #F2F2F2;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
}
.prod-check-options {
  font-size: 1px;
}

button {
    cursor: pointer;
}

button:disabled {
    cursor: default;
    filter: brightness(0.7);
    opacity: .8;
}

.text-danger {
    color: var(--danger);
}
