.survey_container {
	background: var(--white-accent);
	min-height: 100dvh;
	padding: 5rem 0;
}

@media screen and (max-width: 768px) {
	.survey_container {
		padding: 2rem 0;
	}
}
