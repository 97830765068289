.thankyou_container,
.reward_container {
	padding: 2rem;
	max-width: 70dvw;
	background: white;
	min-height: 30dvh;
	margin-top: 9rem;
	padding: 5rem;
	border-radius: 24px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 3rem;
}

.thankyou_container img {
	width: 100%;
	max-width: 100px;
}

.reward_container img {
	width: 100%;
    max-width: 340px;
	height: 260px;
}

.thankyou_note,
.reward_note {
	text-align: center;
}

.reward_note > p {
	text-align: center;
    color: #212121;
}

.reward_note > h2 {
	margin-bottom: 3rem;
}

.thankyou_note h2 {
	margin: 1rem 0;
	background: var(--white-accent);
	padding: 1.1rem;
	border-radius: 1.5rem;
	outline: 1px solid var(--primary);
}

.thankyou_other_section {
	background: var(--white-accent);
	padding: 2rem;
	display: flex;
	flex-direction: column;
	place-items: center;
	width: 90%;
	max-width: 800px;
	margin: 0 auto;
	border-radius: 24px;
	color: #484444;
	font-size: 14px;
	text-align: center;
}

.thankyou_other_section > h3 {
	color: black;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	font-size: 1.5rem;
	margin-bottom: 1rem;
}

.reward_other_section button,
.thankyou_other_section a {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	background: var(--primary);
	color: white;
	padding: 1rem;
	border-radius: 0.5rem;
	border: 0;
	margin: 2rem 0.5rem auto;
	width: 100%;
	max-width: 400px;
    text-decoration: none;
}

.reward_other_section > p {
	margin: 0.5rem 0;
    text-align: center;
    color: #636363;
}

.highlight {
	font-weight: bold;
	color: var(--primary);
}

.sproxil_usp {
	display: flex;
	flex-direction: column;
	margin: 3rem 1rem 0;
}

.sproxil_usp > h3 {
	color: black;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;
	gap: 0.3rem;
	font-size: 1.5rem;
	margin-bottom: 1rem;
}

.sproxil_usp > p {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	text-align: left;
	gap: 0.5rem;
	margin-bottom: 1rem;
}

.sproxil_usp > p svg {
	fill: var(--primary);
}

.aisha_usp {
	background: white;
	padding: 1.3rem 2.2rem;
	border-radius: 1rem;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 2.1rem;
}

.aisha_usp svg {
	font-size: 24px;
	fill: var(--primary);
	margin-bottom: 0.8rem;
}

.aisha_usp img {
	width: 24px;
	fill: var(--primary);
	margin-bottom: 0.8rem;
}

@media screen and (max-width: 1024px) {
	.thankyou_container {
		padding: 1rem;
		max-width: 95dvw;
		margin: 0 auto;
	}
}

@media screen and (max-width: 640px) {
	.thankyou_container {
		border-radius: 0.5rem;
	}

	.reward_container {
		padding: 1rem;
		max-width: 95dvw;
		margin: 0 auto;
		border-radius: 0.5rem;
	}

	.reward_container img {
		width: 100%;
		height: 100%;
	}

	.thankyou_container h3,
	.reward_container h2 {
		font-size: 1rem !important;
	}

	.thankyou_other_section {
		padding: 1rem;
		width: 100%;
		margin: 0 auto;
		border-radius: 0;
		color: #484444;
		font-size: 14px;
		text-align: center;
	}

	.thankyou_other_section > p,
	.sproxil_usp > p {
		font-size: 0.8rem;
	}
	.aisha_usp {
		flex-direction: column;
		gap: 2rem;
		align-items: center;
	}
}
