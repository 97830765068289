.container {
	background: white;
	padding: 5rem;
	max-width: 70dvw;
	margin: 0 auto;
	border-radius: 0.5rem;
}

.introduction_wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.introduction_wrapper > h2 {
	margin-top: 1rem;
	margin: 1.4rem 0;
	color: black;
	font-size: 2.1rem;
}

.introduction_wrapper > p {
	max-width: 652px;
	margin: 0 auto;
	color: black;
}

.introduction_icons {
	width: 100%;
	margin-top: 5rem;
}

.survey_icon_summary {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	background: var(--white-accent);
	padding: 1.2rem;
	border-radius: 0.8rem;
	margin-bottom: 1rem;
}

.survey_icon_summary p {
	width: 260px;
	text-align: left;
}
.survey_icon_summary span {
	color: var(--primary);
}

.consent {
	margin-top: 12rem;
	max-width: 540px;
}

.consent label {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 1rem;
}

.consent p {
	color: #636363;
	font-weight: 300;
	margin-bottom: 1.2rem;
}

.consent p a {
	color: var(--primary);
}

.consent button {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
	background: var(--primary);
	color: white;
	padding: 1rem;
	border-radius: 0.5rem;
	border: 0;
	margin: 0 auto;
	margin-bottom: 1rem;
	width: 100%;
	max-width: 340px;
}

.consent button svg {
	fill: white;
}

.sponsors {
	display: flex;
	align-items: center;
	gap: 1rem;
	margin-top: 6rem;
}

.sponsors img {
	width: 100%;
	max-width: 140px;
}

.sponsors img:nth-child(2) {
	width: 100%;
	max-width: 140px;
	max-height: 50px;
}

@media screen and (max-width: 768px) {
	.container {
		padding: 1.2rem;
		max-width: 95dvw;
	}

	.introduction_wrapper > h2 {
		font-size: 1.4rem;
	}

	.consent {
		margin-top: 5rem;
	}

	.consent label {
		font-size: 0.8rem;
		width: fit-content;
		margin: 0 auto;
		margin-bottom: 0.5rem;
	}
}

@media screen and (max-width: 498px) {
	.container .image_wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.introduction_wrapper > h2 {
		font-size: 1.4rem;
	}

	.sponsors {
		gap: 0.3rem;
	}

	.sponsors img {
		width: 70%;
		scale: 0.8;
	}
}
